import logo from './logo.svg';
import './App.css';
function App() {
  console.log(process.env)
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          You are running on {process.env.REACT_APP_ENVIRONMENT} environment platform and yaya again and againn and aaagain and gaingain
        </p>
        <p>Commit ID: {process.env.REACT_APP_COMMIT_ID}</p>
      </header>
    </div>
  );
}
export default App;

